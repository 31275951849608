.Layout_main__BqQ1G {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
  width: 100vw;

  position: relative;
  z-index: 2;

  overflow-y: scroll;
  overflow-x: hidden;
}

.__404_main__s_e_Q {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1vw;
}

.__404_divider__Pp0zJ {
  width: 2px;
  height: 4rem;
  background: #454545;
}

.__404_errorCode__f0my9 {
  font-size: 48px;
  color: #000;
  margin: 0px;
}

.__404_errorText__TA3rE {
  font-size: 28px;
  color: #333;
  margin: 0px;
}

/* latin-ext */
@font-face {
  font-family: '__Lato_f5cf88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/162938472036e0a8-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lato_f5cf88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6d664cce900333ee-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Lato_Fallback_f5cf88';src: local("Arial");ascent-override: 101.32%;descent-override: 21.87%;line-gap-override: 0.00%;size-adjust: 97.42%
}.__className_f5cf88 {font-family: '__Lato_f5cf88', '__Lato_Fallback_f5cf88';font-weight: 700;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7c53f7419436e04b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1c57ca6f5208a29b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cff529cd86cc0276-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7be645d133f3ee22-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5647e4c23315a2d2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3dbd163d3bb09d47-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/934c4b7cb736f2a3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_792391';src: local("Arial");ascent-override: 92.67%;descent-override: 24.39%;line-gap-override: 0.00%;size-adjust: 100.11%
}.__className_792391 {font-family: '__Roboto_792391', '__Roboto_Fallback_792391';font-weight: 400;font-style: normal
}

