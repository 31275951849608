.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1vw;
}

.divider {
  width: 2px;
  height: 4rem;
  background: #454545;
}

.errorCode {
  font-size: 48px;
  color: #000;
  margin: 0px;
}

.errorText {
  font-size: 28px;
  color: #333;
  margin: 0px;
}
